import React, { useEffect } from 'react';
import * as styles from './AboutSection.module.scss';
import Header from '../Header';
import Card from '../Card';
import GoogleAd from '../GoogleAd';
import Icon from '../Icon';
import { adIcon } from '../../utilities/icons';

interface AboutSectionProps {
    content: string,
    category: string,
    more: PageProps[],
}

interface PageProps {
    icon: string,
    title: string,
    slug: string
}

const AboutSection = ({ content, category, more }: AboutSectionProps) => {
    return (
        <div className={styles.aboutSection}>
            <div className={styles.aboutSectionMain}>
                <div className={styles.adHeader}>
                    <Icon icon={adIcon} style={styles.adIcon} />
                    <span className={styles.adText}>advertisement</span>
                </div>
                <div className={styles.adSection}>
                    <div className={styles.ad}>
                        <GoogleAd divId="div-gpt-ad-1620602262128-0" width="300px" height="250px" />
                    </div>
                    <div className={styles.ad}>
                        <GoogleAd divId="div-gpt-ad-1620602285913-0" width="300px" height="250px"/>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{__html: `${content}`}} />
                <Header type="h3" light={true} text={`More ${category} Generators`} />
                <div className={styles.moreCards}>
                    {more.map((page) =>
                        <Card key={page.title} title={page.title} category={category} icon={page.icon} url={page.slug} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default AboutSection;
