import React, { useState } from 'react';
import Layout, { FullWidthContainer } from '../../components/Layout';
import { graphql, navigate } from 'gatsby';
import Header from '../../components/Header';
import * as styles from './FixedAttr.module.scss';
import GeneratorBox from '../../components/GeneratorBox';
import Button from '../../components/Button';
import { generateIcon, piIcon } from '../../utilities/icons';
import AboutSectionWithCard from '../../components/AboutSectionWithCard';
import AboutSection from '../../components/AboutSection';
import CategorySection from '../../components/CategorySection';
import DisclaimerSection from '../../components/DisclaimerSection';
import Footer from '../../components/Footer';
import Meta from '../../components/Meta';
import { randNumberRange } from '../../utilities/random';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const FixedAttr = ({ pageContext, data }) => {
  const {
      content,
      description,
      metaImageLocal,
      includeImage,
      hideCard,
      randomWords,
      icon,
      title,
      type
  } = data.mongodbGeneratorninjaFixedattrs;
    const [randomNumber, setRandomNumber] = useState(randNumberRange(0, randomWords.length));
    const [shake, setShake] = useState('');
    const { category } = pageContext;

    let pages = [];
    let randomPages = [];
    data.allMongodbGeneratorninjaFixedattrs.nodes.forEach((node) => {
      if (node.category === category) {
        pages.push({icon: node.icon, title: node.title, slug: node.slug});
      }
      randomPages.push(node.slug);
    });
    data.allMongodbGeneratorninjaRandomattrs.nodes.forEach((node) => {
      if (node.category === category) {
        pages.push({icon: node.icon, title: node.title, slug: node.slug});
      }
      randomPages.push(node.slug);
    });
    data.allMongodbGeneratorninjaNocards.nodes.forEach((node) => {
      if (node.category === category) {
        pages.push({icon: node.icon, title: node.title, slug: node.slug});
      }
      randomPages.push(node.slug);
    });

    const randomUrl = () => {
      navigate(
        `${randomPages[randNumberRange(0, randomPages.length)]}`
      )
    }

    const getRandomNumber = () => {
      setShake('');
      const rand = randNumberRange(0, randomWords.length);
      const oldRand = randomNumber;
      setRandomNumber(rand);
      if (rand === oldRand) return setShake('animate__flash animate__infinite');
    }

    return (
      <>
          <Layout>
            <>
              <Meta title={title} description={description} path={pageContext.slug}  image={metaImageLocal.publicURL} />
              <FullWidthContainer>
                <>
                  <Header type="h1" text={title} />
                  <div className={`${includeImage ? styles.randomImage : ''}`}>
                    {includeImage ? <GatsbyImage image={getImage(randomWords[randomNumber].imageLocal)} alt={randomWords[randomNumber].word} /> : ''}
                  </div>
                  <GeneratorBox largeFont={type === 'Emoji'}>
                    <div className={`animate__animated ${shake}`}><span>{randomWords[randomNumber].word}</span></div>
                  </GeneratorBox>
                  <Button type="Function" light={true} text={`Generate Another ${type}`} handleClick={getRandomNumber} icon={generateIcon} />
                  <Button type="Function" light={false} text={`Go to a Random Generator`} handleClick={() => randomUrl()} icon={piIcon} />
                </>
              </FullWidthContainer>
              {!hideCard
                ?   <AboutSectionWithCard
                      more={pages}
                      content={content}
                      category={pageContext.category}
                      icon={icon}
                      type={type}
                      name={randomWords[randomNumber].word}
                      attributes={randomWords[randomNumber].attributes}
                    />
                :   <AboutSection
                      more={pages}
                      content={content}
                      category={pageContext.category}
                    />
              }
              <CategorySection  />
              <DisclaimerSection />
              <Footer />
              </>
          </Layout>
        </>
    )
}

export default FixedAttr;

export const query = graphql`
query FixedAttrPagesTemplate($slug: String!) {
    mongodbGeneratorninjaFixedattrs(slug: {eq: $slug}) {
      content
      description
      icon
      includeImage
      hideCard
      metaImageLocal {
        publicURL
      }
      randomWords {
        attributes {
          name
          value
        }
        word
        imageLocal {
          childImageSharp{
            gatsbyImageData(height: 150, formats: [AUTO], placeholder: NONE)
          }
        }
      }
      title
      type
    }
    allMongodbGeneratorninjaRandomattrs(
      filter: {active: {eq: true}}
    ) {
      nodes {
        title
        icon
        slug
        category
      }
    }
    allMongodbGeneratorninjaNocards(
      filter: {active: {eq: true}}
    ) {
      nodes {
        title
        icon
        slug
        category
      }
    }
    allMongodbGeneratorninjaFixedattrs(
      filter: {active: {eq: true}, slug: {ne: $slug}}
    ) {
      nodes {
        title
        icon
        slug
        category
      }
    }
  }
`;
